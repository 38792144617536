import React, { ReactElement } from 'react';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { SymbolTextAreaDeltaInput } from './SymbolTextAreaDeltaInput';

interface IProps {
  readonly name: string;
  readonly text: string;
  readonly onTextDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextAreaFieldEditor = ({ name, text, onTextDelta }: IProps): ReactElement => {
  return (
    <div className="flex flex-row">
      <div className="mr-2 w-[153px] self-start text-right text-gray-500">
        <span>{name + ':'}</span>
      </div>
      <SymbolTextAreaDeltaInput value={text} onValueDelta={onTextDelta} />
    </div>
  );
};
