import { EnjicalcSheet } from '../model';
import { getWorkspaceItemAuxMetaStrEntry } from './getWorkspaceItemAuxMetaStrEntry';

export interface ISheetAuxMeta {
  checkedName: string;
  checkedDate: string;
  approvedName: string;
  approvedDate: string;
}

export const getSheetAuxMeta = (sheet: EnjicalcSheet): ISheetAuxMeta => {
  const checkedName = getWorkspaceItemAuxMetaStrEntry(sheet, 'enjcWorkspaceSheetCheckedName');
  const checkedDate = getWorkspaceItemAuxMetaStrEntry(sheet, 'enjcWorkspaceSheetCheckedDate');
  const approvedName = getWorkspaceItemAuxMetaStrEntry(sheet, 'enjcWorkspaceSheetApprovedName');
  const approvedDate = getWorkspaceItemAuxMetaStrEntry(sheet, 'enjcWorkspaceSheetApprovedDate');
  return {
    checkedName,
    checkedDate,
    approvedName,
    approvedDate,
  };
};
