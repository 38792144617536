import React, { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStringColor } from '../../../utils/getStringColor';
import {
  EnjcWorkspaceItemVisibility,
  EnjicalcWorkspaceItemsRefs,
  getWorkspaceItemTitle,
} from '../../../libenjc/enjc-workspace';
import {
  editSetWorkspaceTitle,
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
} from '../../../libenjc/enjc-workspace-editing';
import { WorkspaceMenu } from './WorkspaceMenu';
import { EnjcTimeAgo } from './EnjcTimeAgo';
import { TbArchiveFilled } from 'react-icons/tb';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';
import { SideBarContext } from 'src/context/SideBarContext';

interface IProps {
  readonly workspace: EnjicalcWorkspaceItemsRefs;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateMessage>;
}

const WorkspaceCardF = ({ workspace, performWorkspaceEdit }: IProps): ReactElement => {
  const navigate = useNavigate();
  const { id: workspaceId } = workspace;
  const title = getWorkspaceItemTitle(workspace);
  const workspaceColor = getStringColor(workspaceId);

  const { onToggle } = React.useContext(SideBarContext);

  const [isOpenRenameTitle, setOpenRenameTitle] = React.useState(false);

  const [titleValue, setTitleValue] = useState<string>(title);

  const setEnjcWorkspaceTitle = React.useCallback(
    (nextTitle: string) => {
      const hEntry = editSetWorkspaceTitle(workspace, nextTitle);
      performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const handleNavigate = useCallback(() => {
    navigate(`/workspace/${workspaceId}`);
    onToggle(true);
  }, [navigate, workspaceId, onToggle]);

  const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTitleValue(e.target.value), []);

  const handleTitleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setEnjcWorkspaceTitle(titleValue);
        setOpenRenameTitle(false);
      }
    },
    [titleValue, setEnjcWorkspaceTitle],
  );

  const handleInputClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const renderTitle = () => {
    if (isOpenRenameTitle) {
      return (
        <input
          className="size-full bg-gray-100 px-0 text-sm focus:border-b-2 focus:outline-none"
          style={{ borderColor: workspaceColor }}
          placeholder="Empty"
          value={titleValue}
          onKeyDown={handleTitleKeyDown}
          onChange={handleTitleChange}
          autoFocus
          onClick={handleInputClick}
        />
      );
    }

    if (titleValue) {
      return <p className="truncate text-black">{titleValue}</p>;
    }

    return <p>Untitled</p>;
  };

  return (
    <div
      className={`mb-[50px] mr-[50px] h-[200px] w-[300px] cursor-pointer rounded-lg border border-black bg-primary-offwhite hover:shadow-xl ${QUICK_START_TOUR_STEP_NAMES.OpenWorkspace}`}
      onClick={handleNavigate}
    >
      <div className="flex h-full flex-col justify-between p-5">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="size-[18px]" style={{ backgroundColor: workspaceColor }} />
              <div className="ml-2 max-w-[180px] flex-1 text-sm">{renderTitle()}</div>
              {workspace.visibility !== EnjcWorkspaceItemVisibility.Visible && (
                <TbArchiveFilled size={15} className="ml-2" title="This workspace is archived" />
              )}
            </div>
            <WorkspaceMenu
              workspace={workspace}
              onOpenRenameTitle={() => setOpenRenameTitle(true)}
              performWorkspaceEdit={performWorkspaceEdit}
            />
          </div>
        </div>

        <div>
          <p className="text-sm text-gray-500">
            <EnjcTimeAgo date={workspace.itemsVersion.timestamp} />
          </p>
        </div>
      </div>
    </div>
  );
};

export const WorkspaceCard = React.memo(WorkspaceCardF);
