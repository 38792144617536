import React, { ReactElement } from 'react';
import {
  useCtxEnjicalcSheet,
  useCtxEnjicalcWorkspaceEditHistory,
  useCtxEnjicalcWorkspace,
} from '../../../libenjc/enjc-react/enjc-react-context';
import {
  EnjcWorkspaceItemVisibility,
  EnjicalcSheetItem,
  getEnjcSymbolOrUndefined,
  getWorkspaceItemTitle,
  TSymbolId,
} from '../../../libenjc/enjc-workspace';
import { useEnjcRawWorkspaceMutations } from '../../../libenjc/enjc-react/enjc-react-client';
import { convertSheetUpdate, exportSheet, exportWorkspace } from '../../../libenjc/enjc-workspace-import-export';
import { EnjcWorkspaceSectionInput, EnjcValueTreeNodeMode } from '../../../libenjc/enjicalc-graphql';
import {
  editMoveSymbolBeforeAfter,
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
  editCreateSectionIn,
  editCreateSymbolIn,
} from '../../../libenjc/enjc-workspace-editing';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../../libenjc/enjc-client';
import { extractUpdateCreatedSymbol } from '../../../libenjc/enjc-client/utils';
import { SymbolModalView } from '../symbol-document-editor/SymbolModalView';
import { useSearchParamsSheetDocumentModalSymbolId } from './useSearchParamsSheetDocumentModalSymbolId';
import { SheetHeader } from './SheetHeader';
import { SheetDocumentEditorItem } from './SheetDocumentEditorItem';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { QUICK_START_TOUR_STEP_COUNT, QUICK_START_TOUR_STEP_NAMES, usePrint, useQuickStartTour } from '../../../hooks';
import { PrintButton } from '../../buttons/PrintButton';
import { IoSettingsOutline } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import { LiaRedoSolid, LiaSaveSolid, LiaUndoSolid } from 'react-icons/lia';
import { isLiteralVoid } from 'src/libenjc/enjc-literal';
import { useLocation } from 'react-router-dom';
import { isNodeEnvDevelopment } from '../../../utils';
import { track } from '@vercel/analytics/react';
import { useUser } from '@clerk/clerk-react';
import { hCreateSymbol, hCreateSymbolIn } from '../../../libenjc/enjc-workspace-editing/actions/editCreateSymbol';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  ScrollArea,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'src/shadcn';
import { PiWarningCircleFill } from 'react-icons/pi';
import { PrintButtonTooltipContext } from 'src/context/PrintButtonTooltipContext';
import { checkCanUndo, checkCanRedo } from '../../../libenjc/enjc-workspace-editing/utils';

const SheetDocumentEditorF = (): ReactElement => {
  const { user } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryActiveSheet = searchParams.get('active');

  const { setCurrentStep, isBeta, isAlpha, setIsOpen, isOpen } = useQuickStartTour();

  const { workspace } = useCtxEnjicalcWorkspace();
  const { sheet } = useCtxEnjicalcSheet();
  // FIXME: review all usages of 'workspaceEditHistoryContext.performEdit()'
  const workspaceEditHistoryContext = useCtxEnjicalcWorkspaceEditHistory();

  const { ref, print } = usePrint(getWorkspaceItemTitle(sheet));

  const { modalSymbolId, setModalSymbolId } = useSearchParamsSheetDocumentModalSymbolId();

  const { showPrintButtonTooltip } = React.useContext(PrintButtonTooltipContext);

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const [showImportSheetUpdateModal, setShowImportSheetUpdateModal] = React.useState(false);
  const [showExportWorkspaceModal, setShowExportWorkspaceModal] = React.useState(false);
  const [showExportSheetModal, setShowExportSheetModal] = React.useState(false);
  const [localSheetItems, setLocalSheetItems] = React.useState<EnjicalcSheetItem[]>(
    sheet.sheetItems as EnjicalcSheetItem[],
  );
  const [updateJson, setUpdateJson] = React.useState('');
  const [currentElementErrorIndex, setCurrentElementErrorIndex] = React.useState<number>(0);

  React.useEffect(() => {
    setLocalSheetItems(sheet.sheetItems as EnjicalcSheetItem[]);
  }, [sheet.sheetItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleSheetSectionCreate = React.useCallback(() => {
    const hEntry = editCreateSectionIn(workspace, sheet.id);
    workspaceEditHistoryContext.performEdit(hEntry);
  }, [workspace, sheet.id, workspaceEditHistoryContext]);

  const handleSheetSymbolCreate = React.useCallback(() => {
    track('create-symbol', { emailAddress: `${user?.primaryEmailAddress}`, id: `${user?.id}` });
    const iche = hCreateSymbolIn(workspaceEditHistoryContext, sheet.id);
    workspaceEditHistoryContext.performEdit(iche.historyEntry).then((updatedWorkspace) => {
      console.debug(`Updated workspace with id ${updatedWorkspace.id}`, updatedWorkspace);

      setTimeout(() => {
        if (isAlpha) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenu);
        } else if (isBeta) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenuBetta);
        } else {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenuGamma);
        }
      }, 0);
    });
    // hCreateSymbol(workspaceEditHistoryContext);
    // const hEntry = editCreateSymbolIn(workspace, sheet.id);
    // performEdit(hEntry);
    //   .then((updateResult) => {
    //   return extractUpdateCreatedSymbol(updateResult);
    // });
  }, [user?.primaryEmailAddress, user?.id, workspaceEditHistoryContext, sheet.id, isAlpha, isBeta, setCurrentStep]);

  // const importSheetUpdate = React.useCallback(() => {
  //   const item = convertSheetUpdate(workspace, sheet, updateJson);
  //   updateWorkspace(workspace.id, -1, { items: [item] }).then((mutationResult) =>
  //     console.debug('[SheetDocumentEditor] importSheetUpdate:updateEnjcWorkspace mutationResult', mutationResult),
  //   );
  // }, [sheet, updateWorkspace, updateJson, workspace]);

  const modalSymbol = React.useMemo(() => {
    return modalSymbolId && queryActiveSheet === sheet.id
      ? getEnjcSymbolOrUndefined(workspace, modalSymbolId)
      : undefined;
  }, [modalSymbolId, queryActiveSheet, sheet.id, workspace]);

  const moveSymbolBeforeAfter = React.useCallback(
    (symbolToMoveId: TSymbolId, symbolBeforeAfterId: TSymbolId, addAfter: boolean) => {
      const hEntry = editMoveSymbolBeforeAfter(workspace, symbolToMoveId, symbolBeforeAfterId, addAfter);
      workspaceEditHistoryContext.performEdit(hEntry);
    },
    [workspace, workspaceEditHistoryContext],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = sheet.sheetItems.findIndex((si) => si.itemData.id === active.id);
    const overIndex = sheet.sheetItems.findIndex((si) => si.itemData.id === over.id);

    if (overIndex !== -1 && activeIndex !== overIndex) {
      const symbolToMoveId = sheet.sheetItems[activeIndex].itemData.id;
      const symbolBeforeAfterId = sheet.sheetItems[overIndex].itemData.id;

      const addAfter = activeIndex < overIndex;
      setLocalSheetItems((items) => {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(activeIndex, 1);
        updatedItems.splice(overIndex, 0, movedItem);
        return updatedItems;
      });

      moveSymbolBeforeAfter(symbolToMoveId, symbolBeforeAfterId, addAfter);
    }
  };

  // const updateSheet = React.useCallback(
  //   (diffInput: EnjcWorkspaceSectionInput) => {
  //     updateWorkspace(workspace.id, -1, {
  //       items: [{ title: '[FIXME] updateSheet', timestamp: Date.now().toString(), section: [diffInput] }],
  //     });
  //   },
  //   [updateWorkspace, workspace.id],
  // );

  const hasErrorsToScroll = React.useMemo(() => {
    if (queryActiveSheet !== sheet.id) {
      return false;
    }

    const symbolOccurrences = localSheetItems.reduce<Record<string, number>>((acc, s) => {
      if (s.itemData.__typename !== 'EnjcWorkspaceSection' && s.itemData?.glyph !== '') {
        acc[s.itemData.glyph] = (acc[s.itemData.glyph] || 0) + 1;
      }
      return acc;
    }, {});

    if (Object.values(symbolOccurrences).some((count) => count > 1)) {
      return true;
    }

    return localSheetItems.some((item) => {
      if (item.itemData.__typename === 'EnjcWorkspaceSection') {
        return false;
      }

      if (isLiteralVoid(item.itemData.valueTree.result)) {
        return true;
      }

      const itemHasErrorInNodes = item.itemData.valueTree.nodes?.some(
        (node) =>
          node.symbol?.id !== undefined &&
          node.mode === EnjcValueTreeNodeMode.Symbol &&
          getEnjcSymbolOrUndefined(workspace, node.symbol?.id)?.visibility !== EnjcWorkspaceItemVisibility.Visible,
      );

      if (itemHasErrorInNodes) {
        return true;
      }

      const sheetSymbolIndex = sheet.symbols.findIndex((sy) => sy.id === item.itemData.id);
      const usedSymbols = item.itemData.valueTree.nodes
        .filter((n) => n.mode === EnjcValueTreeNodeMode.Symbol)
        .map((node) => node.symbol?.id ?? '')
        .filter((syId) => syId.length > 0);

      const errorOrder = usedSymbols.find((uSy) => {
        return sheet.symbols.findIndex((sSy) => sSy.id === uSy) >= sheetSymbolIndex;
      });

      const errorOrderName = errorOrder ? sheet.symbols.find((sSy) => sSy.id === errorOrder)?.glyph : null;

      return !!errorOrderName;
    });
  }, [localSheetItems, workspace, queryActiveSheet, sheet]);

  const scrollToSymbolsError = () => {
    const elements = document.body.getElementsByClassName('symbolError');

    if (elements.length > 0) {
      window.requestAnimationFrame(() =>
        elements[currentElementErrorIndex].scrollIntoView({ behavior: 'smooth', block: 'end' }),
      );
      elements[currentElementErrorIndex].classList.add('highlight-error');
      if (currentElementErrorIndex > 0) {
        elements[(currentElementErrorIndex - 1) % elements.length].classList.remove('highlight-error');
      } else if (elements.length > 1) {
        elements[elements.length - 1].classList.remove('highlight-error');
      }
      setCurrentElementErrorIndex((prevIndex) => (prevIndex + 1) % elements.length);
    }
  };

  const renderItems = React.useMemo(() => {
    return (
      <>
        {localSheetItems.map((sheetItem, index) => (
          <SheetDocumentEditorItem
            key={sheetItem.itemData.id}
            item={sheetItem}
            index={index}
            modalSymbolId={modalSymbolId}
            setModalSymbolId={setModalSymbolId}
            performWorkspaceEdit={workspaceEditHistoryContext.performEdit}
          />
        ))}
      </>
    );
  }, [localSheetItems, modalSymbolId, workspaceEditHistoryContext.performEdit, setModalSymbolId]);

  return (
    <>
      {createPortal(
        <div className="manage-sheet-buttons absolute right-0 top-0 z-10 flex h-[35px] items-center bg-white px-5 pt-1 max-[900px]:hidden">
          <div className="flex items-center">
            <Tooltip delayDuration={100}>
              <TooltipTrigger className={`max-w-[150px] ${hasErrorsToScroll ? '' : 'hidden'}`}>
                <Button
                  variant="sidebar"
                  className="hover:bg-transparent"
                  size="icon"
                  aria-label="Scroll to error"
                  onClick={scrollToSymbolsError}
                >
                  <PiWarningCircleFill color="red" size={15} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Scroll to error</TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger className="cursor-not-allowed">
                <Button
                  variant="sidebar"
                  size="icon"
                  aria-label="Save"
                  onClick={workspaceEditHistoryContext.performSave}
                >
                  <LiaSaveSolid />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Save</TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger className="cursor-not-allowed">
                <Button
                  variant="sidebar"
                  size="icon"
                  aria-label="Undo"
                  disabled={!checkCanUndo(workspaceEditHistoryContext.workspaceEditHistory)}
                  onClick={workspaceEditHistoryContext.performUndo}
                >
                  <LiaUndoSolid />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Undo</TooltipContent>
            </Tooltip>

            <Tooltip delayDuration={100}>
              <TooltipTrigger className="cursor-not-allowed">
                <Button
                  variant="sidebar"
                  size="icon"
                  aria-label="Redo"
                  disabled={!checkCanRedo(workspaceEditHistoryContext.workspaceEditHistory)}
                  onClick={workspaceEditHistoryContext.performRedo}
                >
                  <LiaRedoSolid />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Redo</TooltipContent>
            </Tooltip>
          </div>

          <Tooltip delayDuration={100} open={showPrintButtonTooltip}>
            <TooltipTrigger>
              <PrintButton
                onClick={() => {
                  print();
                  setIsOpen(false);
                }}
              />
            </TooltipTrigger>
            <TooltipContent>If you want to print, click 'Print to PDF'</TooltipContent>
          </Tooltip>

          {isNodeEnvDevelopment() && (
            <DropdownMenu modal={false} open={isOpenMenu} onOpenChange={(open) => setIsOpenMenu(open)}>
              <DropdownMenuTrigger>
                <Button
                  onClick={(e) => e.stopPropagation()}
                  size={'sm'}
                  aria-label={'Symbol Options'}
                  variant="sidebar"
                >
                  <IoSettingsOutline />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent onClick={(e) => e.stopPropagation()} className="border border-transparent shadow-lg">
                <Button
                  variant="ghost"
                  onClick={() => setShowImportSheetUpdateModal(true)}
                  className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
                >
                  {'Import sheet update'}
                </Button>

                <Button
                  variant="ghost"
                  onClick={() => setShowExportWorkspaceModal(true)}
                  className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
                >
                  {'Export workspace'}
                </Button>

                <Button
                  variant="ghost"
                  onClick={() => setShowExportSheetModal(true)}
                  className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
                >
                  {'Export sheet'}
                </Button>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>,
        document.body,
      )}

      <div ref={ref} className="page flex flex-col items-stretch gap-0">
        <SheetHeader workspace={workspace} sheet={sheet} performEdit={workspaceEditHistoryContext.performEdit} />

        <div>
          <div className="text-lg font-semibold text-black">{'Calculations'}</div>

          <div className="tableRow">
            <div className={'emptyCell initColumn emptyCellPrint'} />
            <div className={'description initColumn'}>{'Description'}</div>
            <div className={'symbol initColumn'}>{'Symbol Name'}</div>
            <div className={'value initColumn'}>{'Value'}</div>
            <div className={'unit initColumn'}>{'Unit'}</div>
            <div className={'comment initColumn'}>{'Comment'}</div>
          </div>
        </div>

        <DndContext
          modifiers={[restrictToVerticalAxis]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={localSheetItems.map(({ itemData }) => itemData.id)}
            strategy={verticalListSortingStrategy}
          >
            {renderItems}
          </SortableContext>
        </DndContext>

        <div className="noPrint my-3 flex items-center space-x-2">
          <Button
            className={`w-[210px] text-[15px] ${
              isAlpha
                ? QUICK_START_TOUR_STEP_NAMES.AddSymbol
                : isBeta
                  ? QUICK_START_TOUR_STEP_NAMES.AddSymbolBetta
                  : QUICK_START_TOUR_STEP_NAMES.AddSymbolGamma
            }`}
            onClick={handleSheetSymbolCreate}
          >
            Add Symbol
          </Button>

          <Button className="w-[210px] text-[15px]" variant="outline" onClick={handleSheetSectionCreate}>
            Add Section
          </Button>
        </div>
      </div>

      <SymbolModalView symbol={modalSymbol} onClose={() => setModalSymbolId('')} />

      <Dialog open={showImportSheetUpdateModal} onOpenChange={(open) => setShowImportSheetUpdateModal(open)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{'Import Sheet Update'}</DialogTitle>
            <DialogClose onClick={() => setShowImportSheetUpdateModal(false)} />
          </DialogHeader>
          <div>
            <Textarea placeholder="Update JSON" value={updateJson} onChange={(e) => setUpdateJson(e.target.value)} />
          </div>
          <DialogFooter>
            {/* FIXME: review */}
            {/*<Button onClick={importSheetUpdate}>{'Import'}</Button>*/}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showExportWorkspaceModal} onOpenChange={(open) => setShowExportWorkspaceModal(open)}>
        <DialogContent className="h-[500px]">
          <DialogHeader>
            <DialogTitle>{'Export Workspace'}</DialogTitle>
            <DialogClose onClick={() => setShowExportWorkspaceModal(false)} />
          </DialogHeader>
          <ScrollArea>
            <pre>{exportWorkspace(workspace)}</pre>
          </ScrollArea>
        </DialogContent>
      </Dialog>

      <Dialog open={showExportSheetModal} onOpenChange={(open) => setShowExportSheetModal(open)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{'Export Sheet'}</DialogTitle>
            <DialogClose onClick={() => setShowExportSheetModal(false)} />
          </DialogHeader>
          <ScrollArea>{/*<pre>{exportSheet(workspace, sheet.id)}</pre>*/}</ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const SheetDocumentEditor = React.memo(SheetDocumentEditorF);
