import React, { ReactElement, ReactNode } from 'react';
import { AppActivityBar } from '../app-activity-bar';

interface IProps {
  readonly children: ReactNode;
}

const AppPanelManagerF = ({ children }: IProps): ReactElement => {
  return (
    <div className="flex h-full items-stretch gap-0">
      <AppActivityBar />
      <div className="grow">{children}</div>
    </div>
  );
};

export const AppPanelManager = React.memo(AppPanelManagerF);
