import { mkAtomDelta } from '../../enjc-delta';
import { mkLiteralNumber, mkLiteralVoidNull, UEnjcValueLiteral } from '../../enjc-literal';
import {
  EnjcWorkspaceItemVisibility,
  findWorkspaceItemContainer,
  getEnjcSection,
  TSectionId,
  TSymbolId,
} from '../../enjc-workspace';
import { IEnjcWorkspaceLocalHistory } from '../interfaces';
import { ItemCreateEntry, EnjcWorkspaceSectionDelta, EnjcWorkspaceSymbolDelta } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const hCreateSymbol = (wlh: IEnjcWorkspaceLocalHistory): ItemCreateEntry => {
  // Allocate unique local id for the new section
  const lcrId = wlh.generateLocalCreateId();
  // Create history entry
  const hEntry = mkWorkspaceEditHistoryEntry(
    {
      symbol: [
        {
          id: lcrId,
          visibility: mkAtomDelta(EnjcWorkspaceItemVisibility.Deleted, EnjcWorkspaceItemVisibility.Visible),
        },
      ],
    },
    { title: 'Create Symbol', actComments: ['hCreateSymbol'] },
  );
  return {
    historyEntry: hEntry,
    itemId: lcrId,
  };
  // Add history entry
  // return wlh.performEdit(hEntry).then((updateResult) => {
  //   // Extract created section from the result
  //   // const allocatedId = extractUpdateAllocatedId(updateResult, lcrId);
  //   // if (!allocatedId) {
  //   //   throw Error('Cannot find allocated ID for the created section');
  //   // }
  //   // const createdSection = updateResult.updateValue.sections.find((upSe) => upSe.id === allocatedId.id);
  //   const createdSymbol = updateResult.symbols.find((upSe) => upSe.id === lcrId);
  //   if (!createdSymbol) {
  //     throw Error(`Cannot find created symbol with ID '${lcrId}'`);
  //   }
  //   return createdSymbol;
  // });
};

export const hCreateSymbolIn = (wlh: IEnjcWorkspaceLocalHistory, sectionId: TSectionId): ItemCreateEntry => {
  const existingSection = getEnjcSection(wlh.currentWorkspace, sectionId);

  const hcr = hCreateSymbol(wlh);

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: existingSection.id,
    items: [{ slicePosition: existingSection.items.length, sliceAfter: [hcr.itemId] }],
  };

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: hcr.itemId,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: mkAtomDelta<UEnjcValueLiteral>(mkLiteralVoidNull(), mkLiteralNumber(0)),
        },
      ],
    },
  };

  return {
    ...hcr,
    historyEntry: {
      ...hcr.historyEntry,
      delta: {
        ...hcr.historyEntry.delta,
        section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
        symbol: [...(hcr.historyEntry.delta.symbol ?? []), symbolDelta],
      },
    },
  };
};

export const hCreateSymbolBeforeAfter = (
  wlh: IEnjcWorkspaceLocalHistory,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
) => {
  const containerDstInfo = findWorkspaceItemContainer(wlh.currentWorkspace, symbolBeforeAfterId);
  if (!containerDstInfo) {
    // TODO: report error
    throw new Error('Dst container not found');
  }

  const hcr = hCreateSymbol(wlh);

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: hcr.itemId,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: mkAtomDelta<UEnjcValueLiteral>(mkLiteralVoidNull(), mkLiteralNumber(0)),
        },
      ],
    },
  };

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: containerDstInfo.container.id,
    items: [{ slicePosition: containerDstInfo.index + (addAfter ? 1 : 0), sliceAfter: [hcr.itemId] }],
  };

  return {
    ...hcr,
    historyEntry: {
      ...hcr.historyEntry,
      delta: {
        ...hcr.historyEntry.delta,
        section: [...(hcr.historyEntry.delta.section ?? []), sectionDelta],
        symbol: [...(hcr.historyEntry.delta.symbol ?? []), symbolDelta],
      },
    },
  };
};
