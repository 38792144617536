import React, { ReactElement } from 'react';
import { AppWelcome } from 'src/app/app-welcome';
import { IEnjcItemIdAuxMeta, IItemViewProps } from '../interfaces';

interface IProps<TEnjcItem extends IEnjcItemIdAuxMeta> {
  readonly items: ReadonlyArray<TEnjcItem>;
  readonly selectedItemId: string | undefined;
  readonly onItemSelect: (item: string | undefined) => void;
  readonly onItemClose: (item: string) => void;

  readonly itemView: ({ item }: IItemViewProps<TEnjcItem>) => ReactElement;
}

export const EnjcItemsTabs = <TEnjcItem extends IEnjcItemIdAuxMeta = IEnjcItemIdAuxMeta>({
  items,
  selectedItemId,
  onItemSelect,
  onItemClose,
  itemView: EnjItemView,
}: IProps<TEnjcItem>): ReactElement => {
  const tabIndex = React.useMemo(() => {
    if (selectedItemId === undefined) {
      return 0;
    }
    const itemIndex = items.findIndex((v) => v.id === selectedItemId);
    return itemIndex < 0 ? 0 : itemIndex + 1;
  }, [items, selectedItemId]);

  return (
    <div className="mt-9 h-[calc(100vh-35px)] overflow-y-scroll border-t border-t-gray-300 bg-gray-100 py-5">
      <div className={tabIndex === 0 ? 'block' : 'hidden'}>
        <AppWelcome />
      </div>

      {items.map((item, index) => (
        <div key={item.id} className={tabIndex === index + 1 ? 'block' : 'hidden'}>
          <EnjItemView item={item} />
        </div>
      ))}
    </div>
  );
};
