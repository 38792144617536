import '@fontsource/dejavu-math';

import React, { ReactElement } from 'react';
import { RouterProvider } from 'react-router-dom';
import Confetti from 'react-confetti';

import { router } from './config/router';

import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { PADDING, QUICK_START_TOUR_STYLES, useQuickStartTour } from 'src/hooks';
import { TourProvider } from '@reactour/tour';
import { Spinner, TooltipProvider } from './shadcn';
import { PrintButtonTooltipProvider } from 'src/context/PrintButtonTooltipContext';

const App = (): ReactElement => {
  const { isFinished, QUICK_START_TOUR_STEPS } = useQuickStartTour();

  return (
    <TourProvider
      steps={QUICK_START_TOUR_STEPS}
      padding={PADDING}
      position="bottom"
      styles={QUICK_START_TOUR_STYLES}
      showDots={false}
      showNavigation={false}
      showBadge={false}
      disableKeyboardNavigation={['left', 'right']}
    >
      <TooltipProvider>
        <PrintButtonTooltipProvider>
          {isFinished && <Confetti width={3000} height={1000} />}

          <div className="small-page-message">
            <p>Screen size must be larger than 900 pixels.</p>
          </div>

          <div className="app-content">
            <RouterProvider router={router} fallbackElement={<Spinner />} />
          </div>
          <Analytics />
          <SpeedInsights />
        </PrintButtonTooltipProvider>
      </TooltipProvider>
    </TourProvider>
  );
};

export default App;
