import { EnjcValueTreeFragment } from '../../../generated/graphql/operations';
import {
  isTreeNodeFunction,
  isTreeNodeLiteral,
  isTreeNodeSymbol,
  TValueTreeNodeKey,
} from '../../enjc-symbol-value-tree/tree-node';
import { TSymbolId } from '../../../generated/graphql/types';
import { mkLiteralVoidNull, UEnjcValueLiteral } from '../../enjc-literal';
import { evaluateValueFunction } from './evaluateValueFunction';
import { getTreeNodeByKey } from '../../enjc-symbol-value-tree/tree-methods';

export const evaluateValueTreeBranch = (
  valueTree: EnjcValueTreeFragment,
  nodeKey: TValueTreeNodeKey,
  symbols: Map<TSymbolId, UEnjcValueLiteral>,
): UEnjcValueLiteral => {
  const branchRoot = getTreeNodeByKey(valueTree, nodeKey);
  if (isTreeNodeLiteral(branchRoot)) {
    return branchRoot.literal;
  }

  if (isTreeNodeSymbol(branchRoot)) {
    if (!branchRoot.symbol?.id) return mkLiteralVoidNull();
    return symbols.get(branchRoot.symbol.id) || mkLiteralVoidNull();
  }

  if (isTreeNodeFunction(branchRoot)) {
    if (!branchRoot.funcSpec?.id) return mkLiteralVoidNull();
    const evaluatorArgs = branchRoot.funcArgs.map((arg) => evaluateValueTreeBranch(valueTree, arg.key, symbols));
    return evaluateValueFunction(branchRoot.funcSpec.id, evaluatorArgs);
  }

  throw Error(`Unknown mode in the node: '${JSON.stringify(branchRoot)}'`);
};
