import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';

import separatorVertical from '../../src/svg/separator-vertical.svg';
import separatorHorizontal from '../../src/svg/separator-horizontal.svg';
import { EnjcApolloProvider } from '../apollo';
import { EnjicalcSettingsContext, UserContext } from '../context';
import { AppPanelManager } from '../app/app-panel-manager';
import { SideBar } from '../app/dashboard/SideBar';
import { SideBarProvider } from '../context/SideBarContext';
import { settingsContextValue } from './settingsContextValue';

export { ErrorRoute } from './error';
export { WorkspaceRoute } from './workspace';
export { WorkspacesDashboardRoute } from './workspaces';
export { SheetRoute } from './sheet';
export { SymbolRoute } from './symbol';

const userContextValue = {
  currentUser: null,
};

export const RootUnsecured = (): ReactElement => {
  // TODO: handle unset environment variable
  return (
    <ClerkProvider
      appearance={{
        variables: {
          colorPrimary: '#10753B',
          fontFamily: 'Plus Jakarta Sans',
          colorText: 'black',
          colorTextSecondary: 'black',
        },
      }}
      publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
      allowedRedirectOrigins={['app.enji.io', 'enji.pro']}
    >
      <UserContext.Provider value={userContextValue}>
        <div className="h-screen">
          <Outlet />
        </div>
      </UserContext.Provider>
    </ClerkProvider>
  );
};

export const RootDashboard = (): ReactElement => {
  return (
    <SideBarProvider>
      <EnjicalcSettingsContext.Provider value={settingsContextValue}>
        <SignedIn>
          <EnjcApolloProvider>
            <div className="flex h-screen justify-between gap-0 bg-primary-offwhite">
              <SideBar />
              <img className="h-screen" src={separatorVertical} alt="Vertical separator" />
              <img
                className="absolute left-0 top-[60px] w-screen"
                src={separatorHorizontal}
                alt="Horizontal separator"
              />
              <Outlet />
            </div>
          </EnjcApolloProvider>
        </SignedIn>
        <SignedOut>
          <Navigate to={'/sign-in'} />
        </SignedOut>
      </EnjicalcSettingsContext.Provider>
    </SideBarProvider>
  );
};

export const RootSecured = (): ReactElement => {
  return (
    <SideBarProvider>
      <EnjicalcSettingsContext.Provider value={settingsContextValue}>
        <SignedIn>
          <EnjcApolloProvider>
            <AppPanelManager>
              <Outlet />
            </AppPanelManager>
          </EnjcApolloProvider>
        </SignedIn>
        <SignedOut>
          <Navigate to={'/sign-in'} />
        </SignedOut>
      </EnjicalcSettingsContext.Provider>
    </SideBarProvider>
  );
};
