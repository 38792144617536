import React, { ReactElement } from 'react';
import { UEnjcValueLiteral, valueLiteralToString } from '../../../libenjc/enjc-literal';
import { NumberDraftPopover } from '../../misc/NumberDraftPopover';

interface IProps {
  readonly draft: string;
  readonly literal: UEnjcValueLiteral;
  readonly onValueSave: (value: string) => void;
}

export const SymbolResultViewDraftEditable = ({ draft, literal, onValueSave }: IProps): ReactElement => {
  // FIXME: review
  const formattedValue = valueLiteralToString(literal);

  return (
    <NumberDraftPopover draft={draft} onSave={onValueSave}>
      <div className={'editableValue'}>
        <span className="green-dot" />
        {formattedValue}
      </div>
    </NumberDraftPopover>
  );
};
