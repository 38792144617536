import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const defaultOptions = {} as const;
export const EnjcAuxMetaStringEntryFragmentDoc = gql`
  fragment EnjcAuxMetaStringEntry on EnjcAuxMetaStringEntry {
    name
    valueStr
  }
`;
export const EnjcAuxMetaFragmentDoc = gql`
  fragment EnjcAuxMeta on EnjcAuxMeta {
    items {
      ...EnjcAuxMetaStringEntry
    }
  }
  ${EnjcAuxMetaStringEntryFragmentDoc}
`;
export const EnjcUserFragmentDoc = gql`
  fragment EnjcUser on EnjcUser {
    id
    auxMeta {
      ...EnjcAuxMeta
    }
  }
  ${EnjcAuxMetaFragmentDoc}
`;
export const EnjcVersionMetaFragmentDoc = gql`
  fragment EnjcVersionMeta on EnjcVersionMeta {
    counter
    timestamp
  }
`;
export const EnjcWorkspaceItemFragmentDoc = gql`
  fragment EnjcWorkspaceItem on IEnjcWorkspaceItem {
    id
    created
    version {
      ...EnjcVersionMeta
    }
    visibility
    auxMeta {
      ...EnjcAuxMeta
    }
  }
  ${EnjcVersionMetaFragmentDoc}
  ${EnjcAuxMetaFragmentDoc}
`;
export const EnjcWorkspaceSectionItemRefFragmentDoc = gql`
  fragment EnjcWorkspaceSectionItemRef on IEnjcWorkspaceItem {
    id
  }
`;
export const EnjcWorkspaceSectionFragmentDoc = gql`
  fragment EnjcWorkspaceSection on EnjcWorkspaceSection {
    ...EnjcWorkspaceItem
    items {
      ...EnjcWorkspaceSectionItemRef
    }
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${EnjcWorkspaceSectionItemRefFragmentDoc}
`;
export const EnjcValueTreeNodeKeyFragmentDoc = gql`
  fragment EnjcValueTreeNodeKey on EnjcValueTreeNode {
    key
  }
`;
export const EnjcValueLiteralVoidFragmentDoc = gql`
  fragment EnjcValueLiteralVoid on EnjcValueLiteralVoid {
    cause
  }
`;
export const EnjcValueLiteralBooleanFragmentDoc = gql`
  fragment EnjcValueLiteralBoolean on EnjcValueLiteralBoolean {
    bool
  }
`;
export const EnjcValueLiteralNumberFragmentDoc = gql`
  fragment EnjcValueLiteralNumber on EnjcValueLiteralNumber {
    numb
  }
`;
export const EnjcValueLiteralStringFragmentDoc = gql`
  fragment EnjcValueLiteralString on EnjcValueLiteralString {
    str
  }
`;
export const ValueLiteralFragmentDoc = gql`
  fragment ValueLiteral on UEnjcValueLiteral {
    ... on EnjcValueLiteralVoid {
      ...EnjcValueLiteralVoid
    }
    ... on EnjcValueLiteralBoolean {
      ...EnjcValueLiteralBoolean
    }
    ... on EnjcValueLiteralNumber {
      ...EnjcValueLiteralNumber
    }
    ... on EnjcValueLiteralString {
      ...EnjcValueLiteralString
    }
  }
  ${EnjcValueLiteralVoidFragmentDoc}
  ${EnjcValueLiteralBooleanFragmentDoc}
  ${EnjcValueLiteralNumberFragmentDoc}
  ${EnjcValueLiteralStringFragmentDoc}
`;
export const EnjcWorkspaceSymbolRefFragmentDoc = gql`
  fragment EnjcWorkspaceSymbolRef on EnjcWorkspaceSymbol {
    id
  }
`;
export const EnjcValueFunctionSpecRefFragmentDoc = gql`
  fragment EnjcValueFunctionSpecRef on EnjcValueFunctionSpec {
    id
  }
`;
export const EnjcValueTreeNodeFragmentDoc = gql`
  fragment EnjcValueTreeNode on EnjcValueTreeNode {
    key
    tags
    draft
    mode
    literal {
      ...ValueLiteral
    }
    symbol {
      ...EnjcWorkspaceSymbolRef
    }
    funcSpec {
      ...EnjcValueFunctionSpecRef
    }
    funcArgs {
      ...EnjcValueTreeNodeKey
    }
    result {
      ...ValueLiteral
    }
  }
  ${ValueLiteralFragmentDoc}
  ${EnjcWorkspaceSymbolRefFragmentDoc}
  ${EnjcValueFunctionSpecRefFragmentDoc}
  ${EnjcValueTreeNodeKeyFragmentDoc}
`;
export const EnjcValueTreeFragmentDoc = gql`
  fragment EnjcValueTree on EnjcValueTree {
    rootNode {
      ...EnjcValueTreeNodeKey
    }
    nodes {
      ...EnjcValueTreeNode
    }
    result {
      ...ValueLiteral
    }
  }
  ${EnjcValueTreeNodeKeyFragmentDoc}
  ${EnjcValueTreeNodeFragmentDoc}
  ${ValueLiteralFragmentDoc}
`;
export const EnjcWorkspaceSymbolFragmentDoc = gql`
  fragment EnjcWorkspaceSymbol on EnjcWorkspaceSymbol {
    ...EnjcWorkspaceItem
    glyph
    glyphPlaintext
    valueTree {
      ...EnjcValueTree
    }
    aliases
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${EnjcValueTreeFragmentDoc}
`;
export const SectionItemUiFragmentDoc = gql`
  fragment SectionItemUi on UEnjcWorkspaceSectionItem {
    ... on EnjcWorkspaceSection {
      ...EnjcWorkspaceSection
    }
    ... on EnjcWorkspaceSymbol {
      ...EnjcWorkspaceSymbol
    }
  }
  ${EnjcWorkspaceSectionFragmentDoc}
  ${EnjcWorkspaceSymbolFragmentDoc}
`;
export const SectionUiFragmentDoc = gql`
  fragment SectionUi on EnjcWorkspaceSection {
    ...EnjcWorkspaceSection
    items {
      ...SectionItemUi
    }
  }
  ${EnjcWorkspaceSectionFragmentDoc}
  ${SectionItemUiFragmentDoc}
`;
export const EnjcIdMetaFragmentDoc = gql`
  fragment EnjcIdMeta on EnjcIdMeta {
    id
    original
  }
`;
export const EnjcWorkspaceSectionRefFragmentDoc = gql`
  fragment EnjcWorkspaceSectionRef on EnjcWorkspaceSection {
    id
  }
`;
export const EnjcUserRefFragmentDoc = gql`
  fragment EnjcUserRef on EnjcUser {
    id
  }
`;
export const EnjcWorkspaceRefItemsFragmentDoc = gql`
  fragment EnjcWorkspaceRefItems on EnjcWorkspace {
    ...EnjcWorkspaceItem
    sheetBookmarks {
      ...EnjcWorkspaceSectionRef
    }
    owner {
      ...EnjcUserRef
    }
    itemsVersion {
      ...EnjcVersionMeta
    }
    sections {
      ...EnjcWorkspaceSectionRef
    }
    symbols {
      ...EnjcWorkspaceSymbolRef
    }
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${EnjcWorkspaceSectionRefFragmentDoc}
  ${EnjcUserRefFragmentDoc}
  ${EnjcVersionMetaFragmentDoc}
  ${EnjcWorkspaceSymbolRefFragmentDoc}
`;
export const EnjcWorkspaceUpdateValueFragmentDoc = gql`
  fragment EnjcWorkspaceUpdateValue on EnjcWorkspaceUpdateValue {
    workspaces {
      ...EnjcWorkspaceRefItems
    }
    sections {
      ...EnjcWorkspaceSection
    }
    symbols {
      ...EnjcWorkspaceSymbol
    }
  }
  ${EnjcWorkspaceRefItemsFragmentDoc}
  ${EnjcWorkspaceSectionFragmentDoc}
  ${EnjcWorkspaceSymbolFragmentDoc}
`;
export const EnjcWorkspaceUpdateMessageFragmentDoc = gql`
  fragment EnjcWorkspaceUpdateMessage on EnjcWorkspaceUpdateMessage {
    createdIds {
      ...EnjcIdMeta
    }
    updateValue {
      ...EnjcWorkspaceUpdateValue
    }
  }
  ${EnjcIdMetaFragmentDoc}
  ${EnjcWorkspaceUpdateValueFragmentDoc}
`;
export const EnjcWorkspaceSelfDeltaFragmentDoc = gql`
  fragment EnjcWorkspaceSelfDelta on EnjcWorkspaceSelfDelta {
    id
  }
`;
export const EnjcWorkspaceUpdateDeltaFragmentDoc = gql`
  fragment EnjcWorkspaceUpdateDelta on EnjcWorkspaceUpdateDelta {
    workspaces {
      ...EnjcWorkspaceSelfDelta
    }
  }
  ${EnjcWorkspaceSelfDeltaFragmentDoc}
`;
export const EnjcWorkspaceAncestorFragmentDoc = gql`
  fragment EnjcWorkspaceAncestor on EnjcWorkspaceAncestor {
    workspaceId
    itemsVersionCounter
  }
`;
export const EnjicalcWorkspaceFragmentDoc = gql`
  fragment EnjicalcWorkspace on EnjcWorkspace {
    ...EnjcWorkspaceRefItems
    ancestor {
      ...EnjcWorkspaceAncestor
    }
    sheetBookmarks {
      ...EnjcWorkspaceSectionRef
    }
    sections {
      ...EnjcWorkspaceSection
    }
    symbols {
      ...EnjcWorkspaceSymbol
    }
  }
  ${EnjcWorkspaceRefItemsFragmentDoc}
  ${EnjcWorkspaceAncestorFragmentDoc}
  ${EnjcWorkspaceSectionRefFragmentDoc}
  ${EnjcWorkspaceSectionFragmentDoc}
  ${EnjcWorkspaceSymbolFragmentDoc}
`;
export const EnjcValueTreeNodeUiFragmentDoc = gql`
  fragment EnjcValueTreeNodeUi on EnjcValueTreeNode {
    ...EnjcValueTreeNode
    symbol {
      ...EnjcWorkspaceSymbolRef
      glyph
      glyphPlaintext
      valueTree {
        result {
          ...ValueLiteral
        }
      }
    }
    funcSpec {
      ...EnjcValueFunctionSpecRef
      glyph
      glyphPlaintext
    }
  }
  ${EnjcValueTreeNodeFragmentDoc}
  ${EnjcWorkspaceSymbolRefFragmentDoc}
  ${ValueLiteralFragmentDoc}
  ${EnjcValueFunctionSpecRefFragmentDoc}
`;
export const EnjcValueTreeUiFragmentDoc = gql`
  fragment EnjcValueTreeUi on EnjcValueTree {
    ...EnjcValueTree
    nodes {
      ...EnjcValueTreeNodeUi
    }
  }
  ${EnjcValueTreeFragmentDoc}
  ${EnjcValueTreeNodeUiFragmentDoc}
`;
export const SymbolUnwrappedFragmentDoc = gql`
  fragment SymbolUnwrapped on EnjcWorkspaceSymbol {
    ...EnjcWorkspaceSymbol
    valueTree {
      ...EnjcValueTreeUi
    }
  }
  ${EnjcWorkspaceSymbolFragmentDoc}
  ${EnjcValueTreeUiFragmentDoc}
`;
export const EnjicalcSymbolFragmentDoc = gql`
  fragment EnjicalcSymbol on EnjcWorkspaceSymbol {
    ...SymbolUnwrapped
  }
  ${SymbolUnwrappedFragmentDoc}
`;
export const EnjcValueFunctionSpecFragmentDoc = gql`
  fragment EnjcValueFunctionSpec on EnjcValueFunctionSpec {
    id
    glyph
    glyphPlaintext
    aliases
    defaultArgCount
    flagInternal
  }
`;
export const EnjcWorkspaceRefFragmentDoc = gql`
  fragment EnjcWorkspaceRef on EnjcWorkspace {
    id
  }
`;
export const EnjcWorkspaceItemRefFragmentDoc = gql`
  fragment EnjcWorkspaceItemRef on IEnjcWorkspaceItem {
    id
  }
`;
export const GetApiVersionDocument = gql`
  query getApiVersion {
    apiVersion
  }
`;

/**
 * __useGetApiVersionQuery__
 *
 * To run a query within a React component, call `useGetApiVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetApiVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(GetApiVersionDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetApiVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(
    GetApiVersionDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetApiVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(
    GetApiVersionDocument,
    options,
  );
}
export type GetApiVersionQueryHookResult = ReturnType<typeof useGetApiVersionQuery>;
export type GetApiVersionLazyQueryHookResult = ReturnType<typeof useGetApiVersionLazyQuery>;
export type GetApiVersionSuspenseQueryHookResult = ReturnType<typeof useGetApiVersionSuspenseQuery>;
export type GetApiVersionQueryResult = Apollo.QueryResult<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>;
export const GetEnjcMathDocument = gql`
  query getEnjcMath {
    valueFunctions {
      ...EnjcValueFunctionSpec
    }
  }
  ${EnjcValueFunctionSpecFragmentDoc}
`;

/**
 * __useGetEnjcMathQuery__
 *
 * To run a query within a React component, call `useGetEnjcMathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnjcMathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetEnjcMathQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnjcMathQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjcMathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjcMathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
export type GetEnjcMathQueryHookResult = ReturnType<typeof useGetEnjcMathQuery>;
export type GetEnjcMathLazyQueryHookResult = ReturnType<typeof useGetEnjcMathLazyQuery>;
export type GetEnjcMathSuspenseQueryHookResult = ReturnType<typeof useGetEnjcMathSuspenseQuery>;
export type GetEnjcMathQueryResult = Apollo.QueryResult<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>;
export const GetEnjicalcWorkspaceDocument = gql`
  query getEnjicalcWorkspace($id: ID!) {
    workspace: workspaceById(id: $id) {
      ...EnjicalcWorkspace
    }
  }
  ${EnjicalcWorkspaceFragmentDoc}
`;

/**
 * __useGetEnjicalcWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetEnjicalcWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnjicalcWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetEnjicalcWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnjicalcWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables> &
    ({ variables: Types.GetEnjicalcWorkspaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjicalcWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjicalcWorkspaceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetEnjicalcWorkspaceQuery,
    Types.GetEnjicalcWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
export type GetEnjicalcWorkspaceQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceQuery>;
export type GetEnjicalcWorkspaceLazyQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceLazyQuery>;
export type GetEnjicalcWorkspaceSuspenseQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceSuspenseQuery>;
export type GetEnjicalcWorkspaceQueryResult = Apollo.QueryResult<
  Types.GetEnjicalcWorkspaceQuery,
  Types.GetEnjicalcWorkspaceQueryVariables
>;
export const GetWorkspacesListDocument = gql`
  query getWorkspacesList {
    workspaces {
      ...EnjcWorkspaceRefItems
    }
  }
  ${EnjcWorkspaceRefItemsFragmentDoc}
`;

/**
 * __useGetWorkspacesListQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetWorkspacesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspacesListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspacesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspacesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
export type GetWorkspacesListQueryHookResult = ReturnType<typeof useGetWorkspacesListQuery>;
export type GetWorkspacesListLazyQueryHookResult = ReturnType<typeof useGetWorkspacesListLazyQuery>;
export type GetWorkspacesListSuspenseQueryHookResult = ReturnType<typeof useGetWorkspacesListSuspenseQuery>;
export type GetWorkspacesListQueryResult = Apollo.QueryResult<
  Types.GetWorkspacesListQuery,
  Types.GetWorkspacesListQueryVariables
>;
export const GetWorkspaceDocument = gql`
  query getWorkspace($id: ID!) {
    workspaceById(id: $id) {
      ...EnjicalcWorkspace
    }
  }
  ${EnjicalcWorkspaceFragmentDoc}
`;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables> &
    ({ variables: Types.GetWorkspaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspaceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  );
}
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceSuspenseQueryHookResult = ReturnType<typeof useGetWorkspaceSuspenseQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>;
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($diff: EnjcWorkspaceInput) {
    createWorkspace(diff: $diff) {
      ...EnjcWorkspaceUpdateMessage
    }
  }
  ${EnjcWorkspaceUpdateMessageFragmentDoc}
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkspaceMutation, Types.CreateWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateWorkspaceMutation, Types.CreateWorkspaceMutationVariables>(
    CreateWorkspaceDocument,
    options,
  );
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<Types.CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>;
export const UpdateWorkspaceDocument = gql`
  mutation updateWorkspace($workspace: ID!, $generation: Int!, $diff: EnjcWorkspaceInput!) {
    updateWorkspace(workspace: $workspace, generation: $generation, diff: $diff) {
      ...EnjcWorkspaceUpdateMessage
    }
  }
  ${EnjcWorkspaceUpdateMessageFragmentDoc}
`;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *      generation: // value for 'generation'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkspaceMutation, Types.UpdateWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateWorkspaceMutation, Types.UpdateWorkspaceMutationVariables>(
    UpdateWorkspaceDocument,
    options,
  );
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<Types.UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>;
export const ForkWorkspaceDocument = gql`
  mutation forkWorkspace($workspace: ID!, $generation: Int!, $diff: EnjcWorkspaceInput) {
    forkWorkspace(workspace: $workspace, generation: $generation, diff: $diff) {
      ...EnjcWorkspaceUpdateMessage
    }
  }
  ${EnjcWorkspaceUpdateMessageFragmentDoc}
`;
export type ForkWorkspaceMutationFn = Apollo.MutationFunction<
  Types.ForkWorkspaceMutation,
  Types.ForkWorkspaceMutationVariables
>;

/**
 * __useForkWorkspaceMutation__
 *
 * To run a mutation, you first call `useForkWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForkWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [forkWorkspaceMutation, { data, loading, error }] = useForkWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *      generation: // value for 'generation'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useForkWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ForkWorkspaceMutation, Types.ForkWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ForkWorkspaceMutation, Types.ForkWorkspaceMutationVariables>(
    ForkWorkspaceDocument,
    options,
  );
}
export type ForkWorkspaceMutationHookResult = ReturnType<typeof useForkWorkspaceMutation>;
export type ForkWorkspaceMutationResult = Apollo.MutationResult<Types.ForkWorkspaceMutation>;
export type ForkWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.ForkWorkspaceMutation,
  Types.ForkWorkspaceMutationVariables
>;
export const WatchWorkspaceItemsDocument = gql`
  subscription watchWorkspaceItems($workspace: ID!) {
    workspaceUpdate(workspace: $workspace) {
      ...EnjcWorkspaceUpdateMessage
    }
  }
  ${EnjcWorkspaceUpdateMessageFragmentDoc}
`;

/**
 * __useWatchWorkspaceItemsSubscription__
 *
 * To run a query within a React component, call `useWatchWorkspaceItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchWorkspaceItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useWatchWorkspaceItemsSubscription({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useWatchWorkspaceItemsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Types.WatchWorkspaceItemsSubscription,
    Types.WatchWorkspaceItemsSubscriptionVariables
  > &
    ({ variables: Types.WatchWorkspaceItemsSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<Types.WatchWorkspaceItemsSubscription, Types.WatchWorkspaceItemsSubscriptionVariables>(
    WatchWorkspaceItemsDocument,
    options,
  );
}
export type WatchWorkspaceItemsSubscriptionHookResult = ReturnType<typeof useWatchWorkspaceItemsSubscription>;
export type WatchWorkspaceItemsSubscriptionResult = Apollo.SubscriptionResult<Types.WatchWorkspaceItemsSubscription>;
