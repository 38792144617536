import { EnjcWorkspaceDTO, EnjicalcSymbol } from '../../libenjc/enjc-workspace';
import { isTreeNodeLiteral, isTreeNodeSymbol } from '../../libenjc/enjc-symbol-value-tree/tree-node';
import { mkLiteralVoidNull, UEnjcValueLiteral } from '../../libenjc/enjc-literal';
import { evaluateValueTree } from '../../libenjc/enjc-value-math';
import { getTreeNodeByKeyOrUndefined } from '../../libenjc/enjc-symbol-value-tree/tree-methods';

export const getWorkspaceLiteralSymbols = (workspace: EnjcWorkspaceDTO): Map<string, UEnjcValueLiteral> => {
  const literalSymbolsPairs = workspace.symbols.flatMap((sy): [] | [[string, UEnjcValueLiteral]] => {
    const symbolRootNode =
      (sy.valueTree.rootNode && getTreeNodeByKeyOrUndefined(sy.valueTree, sy.valueTree.rootNode.key)) || undefined;
    return isTreeNodeLiteral(symbolRootNode) ? [[sy.id, symbolRootNode?.literal || mkLiteralVoidNull()]] : [];
  });
  return new Map<string, UEnjcValueLiteral>(literalSymbolsPairs);
};
export const getSymbolDependencies = (symbol: EnjicalcSymbol): ReadonlyArray<string> => {
  // TODO: filter unique symbol ids
  return symbol.valueTree.nodes
    .filter((syNode) => isTreeNodeSymbol(syNode) && !!syNode.symbol)
    .map((syNode) => syNode.symbol?.id ?? '');
};
export const evaluateWorkspaceSymbols = (workspace: EnjcWorkspaceDTO): Map<string, UEnjcValueLiteral> => {
  const literalSymbols = getWorkspaceLiteralSymbols(workspace);
  const calcCache = new Map<string, UEnjcValueLiteral>(literalSymbols);
  while (true) {
    let symbolEvaluated = false;
    for (const sy of workspace.symbols) {
      // console.debug('Symbol evaluation step');
      if (!calcCache.has(sy.id)) {
        const deps = getSymbolDependencies(sy);
        if (deps.every((syDe) => calcCache.has(syDe))) {
          // Evaluate
          const symbolEvalLiteral = evaluateValueTree(sy.valueTree, calcCache);
          calcCache.set(sy.id, symbolEvalLiteral);
          // Start next evaluation pass
          symbolEvaluated = true;
          // break;
        }
      }
    }

    // Break if no symbols were evaluated
    if (!symbolEvaluated) break;
  }
  console.debug(`Workspace ${workspace.id} evaluation calcCache result`, calcCache);
  return calcCache;
};
