import React, { ReactElement, useCallback } from 'react';
import { isNodeEnvDevelopment } from '../../../utils';
import { KeyboardEventHandler } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-mathml-view/types';
import { processValueTreeClientDelta } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-mathml-view/utils';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import {
  EnjicalcSymbol,
  EnjicalcWorkspace,
  EnjcWorkspaceDTO,
  EnjcWorkspaceWithMath,
} from '../../../libenjc/enjc-workspace';
import { EnjcSymbolValuePlainView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-plain-view';
import { EnjcValueTreeDelta } from '../../../libenjc/enjc-workspace-editing/model';
import { mkTreeCursorNode, ValueTreeCursor } from '../../../libenjc/enjc-symbol-value-tree/tree-cursor';
import { ValueTreeViewContext } from '../../../libenjc/enjc-react/enjc-react-ui';
import {
  getNodeByKeyNVTV,
  getValueFunctionOrUndefinedNVTV,
  getValueHintEntries,
  getValueSymbolOrUndefinedNVTV,
  IValueTreeViewContext,
} from '../../../libenjc/enjc-value-view-ctx';
import { handleTreeKey } from '../../../libenjc/enjc-symbol-value-tree/tree-navigation-handlers-key';
import { isLiteralNumber, isLiteralVoid, valueLiteralToString } from '../../../libenjc/enjc-literal';
import { EnjcSymbolValueMathmlView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-mathml-view';
import { useCtxEnjicalcSheet } from '../../../libenjc/enjc-react/enjc-react-context';
import { UValueHintItem } from '../../../libenjc/enjc-value-view-ctx/model/IValueTreeViewContext';
import { isTreePositionNode, isTreePositionNodeDraft } from '../../../libenjc/enjc-symbol-value-tree/tree-position';
import { changeTreeNodeFunction, changeTreeNodeSymbol } from '../../../libenjc/enjc-symbol-value-tree/tree-editing';
import { EnjcGlyphMathBlockView, SymbolValueMathBlockView } from '../symbol-math-view';
import { SymbolValueCursorDebugInfo } from './SymbolValueCursorDebugInfo';
import { getTreeNodeByKey } from '../../../libenjc/enjc-symbol-value-tree/tree-methods';
import { isTreeNodeLiteral } from '../../../libenjc/enjc-symbol-value-tree/tree-node';
import { EnjcValueTreeNodeMode } from '../../../generated/graphql/types';
import { EnjcValueFunctionSpecFragment } from '../../../generated/graphql/operations';
import { HoverCard, HoverCardContent, HoverCardTrigger } from 'src/shadcn/components';
import { HiOutlineInformationCircle } from 'react-icons/hi2';

interface IProps {
  readonly workspace: EnjcWorkspaceDTO;
  readonly workspaceMath: {
    readonly functions: ReadonlyArray<EnjcValueFunctionSpecFragment>;
  };
  readonly symbol: EnjicalcSymbol;
  readonly showGlyph?: boolean;
  readonly showResult?: boolean;
  readonly mathProps?: MathMathMLAttributes;
  readonly onSymbolValueDelta: (delta: EnjcValueTreeDelta) => void;
  readonly performUndo: () => void;
  readonly performRedo: () => void;
}

export const SymbolValueSplitEditor = ({
  workspace,
  workspaceMath,
  symbol,
  onSymbolValueDelta,
  performUndo,
  performRedo,
}: IProps): ReactElement => {
  const valueEditorRef = React.useRef<HTMLDivElement>(null);
  const [valueTreeCursor, setValueTreeCursor] = React.useState<ValueTreeCursor>({ position: undefined });
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [valueHintItem, setValueHintItem] = React.useState<UValueHintItem | undefined>(undefined);

  const symbolSheet = useCtxEnjicalcSheet().sheet;

  const rootNode =
    (symbol.valueTree.rootNode?.key && getTreeNodeByKey(symbol.valueTree, symbol.valueTree.rootNode.key)) || undefined;
  const symbolIsSolidLiteral = rootNode && isTreeNodeLiteral(rootNode);

  const processedCursor = React.useMemo(() => {
    const cursorNode = symbol.valueTree.nodes.find((n) => n.key === valueTreeCursor.position?.nodeKey);
    return cursorNode
      ? valueTreeCursor.position &&
        isTreePositionNodeDraft(valueTreeCursor.position) &&
        cursorNode.mode === EnjcValueTreeNodeMode.Literal &&
        cursorNode.draft.length === 0
        ? mkTreeCursorNode(valueTreeCursor.position.nodeKey, true)
        : valueTreeCursor
      : symbol.valueTree.rootNode
        ? mkTreeCursorNode(symbol.valueTree.rootNode.key, true)
        : { position: undefined };
  }, [symbol.valueTree.nodes, symbol.valueTree.rootNode, valueTreeCursor]);

  const processedCursorNode = React.useMemo(() => {
    return symbol.valueTree.nodes.find((n) => n.key === processedCursor.position?.nodeKey);
  }, [processedCursor.position?.nodeKey, symbol.valueTree.nodes]);

  const valueTreeViewCtx: IValueTreeViewContext = React.useMemo(
    () => ({
      workspace: { ...workspace, ...workspaceMath },
      symbol,
      valueTreeCursor: processedCursor,
      valueHintItem,
      hasFocus,
      showValueHints:
        hasFocus &&
        !!processedCursor.position &&
        (isTreePositionNodeDraft(processedCursor.position) ||
          (isTreePositionNode(processedCursor.position) &&
            processedCursorNode?.mode === 'LITERAL' &&
            isLiteralVoid(processedCursorNode.literal) &&
            processedCursorNode.draft.length === 0)),
    }),
    [hasFocus, processedCursor, processedCursorNode, symbol, valueHintItem, workspace, workspaceMath],
  );

  const handleChangeValueTree = useCallback(
    (hint?: UValueHintItem) => {
      if (valueTreeCursor.position?.nodeKey && valueTreeViewCtx.showValueHints && hint) {
        const positionNode = getNodeByKeyNVTV(valueTreeViewCtx, valueTreeCursor.position?.nodeKey);

        const valueHintSymbol = getValueSymbolOrUndefinedNVTV(valueTreeViewCtx, hint.id);
        // FIXME: set symbol without changing other fields
        if (valueHintSymbol) {
          const treeClientDelta = changeTreeNodeSymbol(valueTreeViewCtx, positionNode, valueHintSymbol.id);
          processValueTreeClientDelta(treeClientDelta, onSymbolValueDelta, setValueTreeCursor);
          setValueHintItem(undefined);
          return;
        }

        const valueHintFuncSpec = getValueFunctionOrUndefinedNVTV(valueTreeViewCtx, hint.id);
        if (valueHintFuncSpec) {
          const treeClientDelta = changeTreeNodeFunction(valueTreeViewCtx, positionNode, valueHintFuncSpec);
          processValueTreeClientDelta(treeClientDelta, onSymbolValueDelta, setValueTreeCursor);
          setValueHintItem(undefined);
          return;
        }

        setValueHintItem(undefined);
        return;
      }
    },
    [onSymbolValueDelta, valueTreeCursor.position?.nodeKey, valueTreeViewCtx],
  );

  const handleChangeHintValueByMouse = useCallback(
    (hint: any) => {
      setValueHintItem(hint);
      handleChangeValueTree(hint);
      valueEditorRef.current?.focus();
    },
    [handleChangeValueTree],
  );

  const handleChangeHintValueByKeyboard = React.useCallback(
    (moveUp: boolean) => {
      const valueHints = getValueHintEntries(valueTreeViewCtx, symbol, symbolSheet);
      if (valueHints.length === 0) {
        // Do nothing if there are no hints
        // TODO: check if current ValueHint needs to be reset here
        return;
      }

      const hintIndex = valueHints.findIndex((vh) => vh.item.id === valueHintItem?.id);
      if (hintIndex < 0) {
        setValueHintItem(valueHints[moveUp ? valueHints.length - 1 : 0].item);
        return;
      } else {
        const updatedHintIndex = hintIndex + (moveUp ? -1 : 1);
        const nextHintIndex =
          updatedHintIndex < 0 ? valueHints.length - 1 : updatedHintIndex >= valueHints.length ? 0 : updatedHintIndex;
        setValueHintItem(valueHints[nextHintIndex].item);
        return;
      }
    },
    [symbol, symbolSheet, valueHintItem?.id, valueTreeViewCtx],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = React.useCallback(
    (e) => {
      // Undo / Redo shortcuts
      if (e.key === 'z' && e.metaKey && performUndo) {
        if (e.shiftKey) {
          performRedo();
        } else {
          performUndo();
        }
        e.preventDefault();
        return false;
      }

      // Value hints navigation
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        handleChangeHintValueByKeyboard(e.key === 'ArrowUp');
        e.preventDefault();
        return false;
      }

      valueEditorRef.current?.focus();

      if (e.key === 'Enter') {
        handleChangeValueTree(valueHintItem);
      }

      const treeClientDelta = handleTreeKey(valueTreeViewCtx, { key: e.key });
      processValueTreeClientDelta(treeClientDelta, onSymbolValueDelta, setValueTreeCursor);
      e.preventDefault();
      return false;
    },
    [
      handleChangeValueTree,
      handleChangeHintValueByKeyboard,
      onSymbolValueDelta,
      performRedo,
      performUndo,
      valueHintItem,
      valueTreeViewCtx,
    ],
  );

  const handleClick = React.useCallback(() => {
    // console.log('SymbolValueEditor handleClick');
    // valueViewRef.current?.focus();
  }, []);

  const handleFocus = React.useCallback(() => {
    // console.log('SymbolValueEditor handleFocus, position is ', valueTreeCursor.position);
    setHasFocus(true);
    if (!valueTreeCursor.position) {
      if (symbol.valueTree.rootNode) {
        setValueTreeCursor(mkTreeCursorNode(symbol.valueTree.rootNode.key, true));
      }
    }
  }, [symbol.valueTree.rootNode, valueTreeCursor.position]);

  const handleBlur = React.useCallback(() => {
    setHasFocus(false);
  }, []);

  // <ValueTreeNodesContext.Provider value={valueTreeNodesCtx}>
  // <ValueTreeCursorContext.Provider value={valueTreeCursorCtx}>
  // <ValueTreeViewContext.Provider value={valueTreeViewContext}>

  const handleNodeClick = React.useCallback((nodeKey: string) => {
    setValueTreeCursor(mkTreeCursorNode(nodeKey, true));
  }, []);

  return (
    <ValueTreeViewContext.Provider value={valueTreeViewCtx}>
      <div className="flex flex-col items-start">
        <ValueTreeViewContext.Provider value={{ ...valueTreeViewCtx, hasFocus: false }}>
          <div className="flex scale-125 items-center gap-2 py-3 font-['Latin_Modern_Math']">
            <div className="flex w-40 justify-end">
              <EnjcGlyphMathBlockView glyph={symbol.glyph} mathProps={{}} />
            </div>
            <code className="text-lg">{' = '}</code>
            <EnjcSymbolValueMathmlView
              viewOptions={{ literalsAsDrafts: true, numerical: false }}
              onNodeClick={handleNodeClick}
            />
            {!symbolIsSolidLiteral && (
              <>
                <code className="text-lg">{' = '}</code>
                <span className="text-[13px]">
                  {isLiteralNumber(symbol.valueTree.result) ? valueLiteralToString(symbol.valueTree.result) : 'NaN'}
                </span>
              </>
            )}
          </div>
        </ValueTreeViewContext.Provider>

        <div className="flex items-center gap-2 font-['Latin_Modern_Math']">
          <div className="flex w-40 items-center justify-end gap-1 font-sans">
            <HoverCard>
              <HoverCardTrigger>
                <HiOutlineInformationCircle style={{ fontSize: '1.2em' }} />
              </HoverCardTrigger>
              <HoverCardContent className="w-[300px]">
                <div>
                  This Formula Editor is not the classic text editor but rather a custom just-it-time compiler built
                  from scratch. It is going to take us some time before the UX will be just what you would expect from a
                  regular editor.
                </div>
                <br />
                <div>
                  We advise operating on this editor only using the left/right buttons to navigate the cursor and the
                  top/down buttons to choose symbols/functions from the selector. If you can't see the cursor, click
                  inside the editor and try pressing the left/right button.
                </div>
              </HoverCardContent>
            </HoverCard>
            <div className="text-right font-sans text-gray-500">Formula Editor:</div>
          </div>
          <div
            id="symbol-value-plain-view"
            ref={valueEditorRef}
            className={`w-[700px] rounded-sm border p-3 ${hasFocus ? 'border-blue-500' : 'border-gray-300'}`}
            onBlur={handleBlur}
            onClick={handleClick}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <EnjcSymbolValuePlainView
              viewOptions={{ literalsAsDrafts: true, numerical: false }}
              onNodeClick={handleNodeClick}
              onHintClick={handleChangeHintValueByMouse}
            />
            {/*<SymbolValuePlainEditor />*/}
          </div>
        </div>

        {false && isNodeEnvDevelopment() && (
          <ValueTreeViewContext.Provider value={{ ...valueTreeViewCtx, hasFocus: false }}>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Value dbg (draft)'}</div>
              <div className="flex flex-col border border-red-500">
                <EnjcSymbolValuePlainView viewOptions={{ literalsAsDrafts: true, numerical: false }} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Value dbg (values)'}</div>
              <div className="flex flex-col border border-red-500">
                <EnjcSymbolValuePlainView viewOptions={{ literalsAsDrafts: false, numerical: false }} />
              </div>
            </div>

            <div className="flex items-center">
              <div className="w-36 text-right">{'Value dbg (numerical)'}</div>
              <div className="flex flex-col border border-red-500">
                <EnjcSymbolValuePlainView viewOptions={{ literalsAsDrafts: false, numerical: true }} />
              </div>
              {/*<p>{isLiteralNumber(symbol.valueTree.result) ? symbol.valueTree.result.numb.toString() : 'NaN'}</p>*/}
            </div>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Cursor Debug Info:'}</div>
              <div className="flex flex-col border border-red-500">
                <SymbolValueCursorDebugInfo />
              </div>
            </div>
          </ValueTreeViewContext.Provider>
        )}

        {false && isNodeEnvDevelopment() && (
          <>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Value Tree Debug:'}</div>
              <div className="flex flex-col border border-red-500">
                <EnjcSymbolValuePlainView viewOptions={{ literalsAsDrafts: true, numerical: false }} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Cursor Debug Info:'}</div>
              <div className="flex flex-col border border-red-500">
                <SymbolValueCursorDebugInfo />
              </div>
            </div>

            <div className="flex items-center">
              <div className="w-36 text-right">{'Formula Preview:'}</div>
              <div className="flex flex-col p-3">
                <SymbolValueMathBlockView
                  symbol={symbol}
                  showGlyph
                  showSymbolic
                  // showNumeric
                  // showResult
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-36 text-right">{'Formula Calculation:'}</div>
              <div className="flex flex-col p-3">
                <SymbolValueMathBlockView
                  symbol={symbol}
                  // showGlyph
                  // showSymbolic
                  showNumeric
                  showResult
                />
              </div>
            </div>
          </>
        )}
      </div>
    </ValueTreeViewContext.Provider>
  );
};

// export const SymbolValueSplitEditor = ({
//   workspace,
//   symbol,
//   showGlyph,
//   showResult,
//   mathProps,
//   onSymbolValueDelta,
// }: IProps): ReactElement => {
//   // FIXME: review source data for this context
//   const { sheet } = useCtxEnjicalcSheet();
//
//   const valueHints = getEnjcSheetValueHints(workspace, sheet, symbol);
//
//   const valueTreeNodesCtx = useValueTreeNodesContextState(symbol);
//   const { rootNodeKey } = valueTreeNodesCtx;
//
//   const valueTreeCursorCtx = useValueTreeCursorState(rootNodeKey);
//   const { valueTreeCursor } = valueTreeCursorCtx;
//
//   const valueTreeViewContext = useValueTreeViewContextState(valueTreeNodesCtx, valueHints, valueTreeCursor);
//   const positionNode =
//     valueTreeCursor.position && getTreeNodeByKey(valueTreeNodesCtx, valueTreeCursor.position.nodeKey);
//
//   // const showNodeEditor = React.useMemo(
//   //   () =>
//   //     isTreePositionNodeDraft(valueTreeCursorCtx.valueTreeCursor.position) &&
//   //     (!isTreeNodeLiteral(positionNode) || isLiteralVoid(positionNode.literal)),
//   //   [positionNode, valueTreeCursorCtx.valueTreeCursor.position],
//   // );
//
//   const handleKeyDownNg: FDivKeyboardEventHandler = (e) => {
//     const eventKey = e.key;
//     // FIXME: implement
//     const treeClientDelta = handleTreeKey();
//     processValueTreeClientDelta(treeClientDelta, onSymbolValueDelta, setValueTreeCursor);
//   };
//
//   const handleKeyDown: FDivKeyboardEventHandler = (e) => {
//     const eventKey = e.key;
//
//     // FIXME: add modifiers
//     //  && !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey
//     if (valueTreeViewContext.onKeyDown) {
//       const navResult = valueTreeViewContext.onKeyDown({ key: eventKey });
//       processValueTreeClientDelta(navResult, onSymbolValueDelta);
//       // TODO: review second argument
//       processValueTreeNavigationResult(navResult, { ...valueTreeNodesCtx, ...valueTreeCursorCtx });
//       onSymbolValueDelta(treeClientDelta.delta);
//     }
//   };
//
//   const onSymbolSelect = (symbolId: TSymbolId) => {
//     if (positionNode) {
//       const navResult = replaceValueTreeNode(
//         valueTreeNodesCtx,
//         positionNode,
//         mkTreeSymbol('', positionNode.draft, symbolId),
//       );
//       processValueTreeNavigationResult(navResult, { ...valueTreeNodesCtx, ...valueTreeCursorCtx });
//     }
//   };
//
//   const onFunctionSelect = (functionId: TValueFunctionId) => {
//     if (positionNode) {
//       const navResult = replaceValueTreeNode(
//         valueTreeNodesCtx,
//         positionNode,
//         mkTreeFunction('', positionNode.draft, functionId, []),
//       );
//       processValueTreeNavigationResult(navResult, { ...valueTreeNodesCtx, ...valueTreeCursorCtx });
//     }
//     // console.log('SymbolValueEditor onFunctionSelect');
//   };
// };
