import { createContext, ReactNode, useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

interface ISideBarContext {
  isShow: boolean;
  onToggle: (forceOpen?: boolean) => void;
}

export const SideBarContext = createContext<ISideBarContext>({
  isShow: true,
  onToggle: () => {},
});

export const SideBarProvider = ({ children }: { children: ReactNode }) => {
  const [isShow, setIsShow] = useLocalStorage('toggleSidebar', true);

  const handleToggle = useCallback(
    (forceOpen?: boolean) => {
      console.log('forceOpen: ', forceOpen);
      if (typeof forceOpen === 'boolean') {
        setIsShow(true);
      } else {
        setIsShow((prevState) => !prevState);
      }
    },
    [setIsShow],
  );

  const value = {
    isShow,
    onToggle: handleToggle,
  };

  return <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>;
};
