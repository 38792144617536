import React, { ReactElement } from 'react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { RiDraggable, RiMore2Line } from 'react-icons/ri';
import { AiOutlineInsertRowBelow } from 'react-icons/ai';
import { DocumentItemIndexView, TextPopover } from '../../misc';
import { EnjicalcSection, getWorkspaceItemTitle } from '../../../libenjc/enjc-workspace';
import {
  editCreateSymbolIn,
  editHideSection,
  editSetSectionTitle,
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
} from '../../../libenjc/enjc-workspace-editing';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';
import { extractUpdateCreatedSymbol } from '../../../libenjc/enjc-client/utils';
import { IoCloseSharp } from 'react-icons/io5';
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger, DropdownMenuSeparator } from 'src/shadcn';

interface IProps {
  readonly index: number;
  readonly section: EnjicalcSection;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => void;
  readonly dndListeners: SyntheticListenerMap | undefined;
}

export const SectionHeaderView = ({ index, section, performWorkspaceEdit, dndListeners }: IProps): ReactElement => {
  const { workspace } = useCtxEnjicalcWorkspace();

  const [showOverlayButtons, setShowOverlayButtons] = React.useState(false);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);

  const setSectionTitle = React.useCallback(
    (sectionId: string, title: string) => {
      const hEntry = editSetSectionTitle(workspace, sectionId, title);
      performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const onSectionDissolve = React.useCallback(() => {
    setIsOpenMenu(false);
    const hEntry = editHideSection(workspace, section.id);
    performWorkspaceEdit(hEntry);
  }, [performWorkspaceEdit, section.id, workspace]);

  const onSectionCreateSymbol = React.useCallback(() => {
    setIsOpenMenu(false);
    const hEntry = editCreateSymbolIn(workspace, section.id);
    performWorkspaceEdit(hEntry);
    //   .then((updateResult) => {
    //   return extractUpdateCreatedSymbol(updateResult);
    // });
    // .then((sy) => {
    //   setModalSymbolId(sy.id);
    // });
  }, [performWorkspaceEdit, section.id, workspace]);

  return (
    <div
      className="flex flex-col"
      onMouseOver={() => setShowOverlayButtons(true)}
      onMouseLeave={() => setShowOverlayButtons(false)}
    >
      <DocumentItemIndexView index={index} />
      {showOverlayButtons && (
        <RiDraggable {...dndListeners} size={17} color="black" style={{ position: 'absolute', top: 5, left: -75 }} />
      )}
      <div className="noPrint relative left-[780px] size-0">
        <div className="absolute left-0 flex">
          <DropdownMenu modal={false} open={isOpenMenu} onOpenChange={(open) => setIsOpenMenu(open)}>
            <DropdownMenuTrigger>
              <Button onClick={(e) => e.stopPropagation()} size={'sm'} aria-label={'section options'} variant="ghost">
                <RiMore2Line />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent onClick={(e) => e.stopPropagation()} className="border border-transparent shadow-lg">
              <Button
                variant="ghost"
                onClick={onSectionDissolve}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <IoCloseSharp size={17} className="mr-2 text-red-600" />
                {'Delete Section'}
              </Button>

              <DropdownMenuSeparator />

              <Button
                variant="ghost"
                onClick={onSectionCreateSymbol}
                className="flex w-full items-center justify-start p-2 font-normal hover:bg-gray-100"
              >
                <AiOutlineInsertRowBelow size={17} className="mr-2 text-black" />
                {'Create Symbol'}
              </Button>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <TextPopover initialValue={getWorkspaceItemTitle(section)} onSave={(title) => setSectionTitle(section.id, title)}>
        <div className="block flex-1 border-t border-solid border-gray-300 p-0 py-[5px] font-['Plus_Jakarta_Sans'] text-[13px] font-semibold text-[rgba(0,0,0,0.8)] hover:cursor-pointer hover:bg-[#f4f8fa]">
          {getWorkspaceItemTitle(section) || 'Untitled Section'}
        </div>
      </TextPopover>
    </div>
  );
};
