import logo from '../../svg/enjicalc-black-logo.svg';
import { SidebarButton } from './SidebarButton';
import { QUICK_START_TOUR_STEP_NAMES, useQuickStartTour } from 'src/hooks';
import { useWorkspacesList } from 'src/libenjc/enjc-react/enjc-react-client';
import { useLocalStorage } from 'usehooks-ts';
import React from 'react';
import { Button, Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'src/shadcn';

export const SideBar = () => {
  const { loading } = useWorkspacesList();
  const { handleStart } = useQuickStartTour();

  const [isFirstTimeLogin, setIsFirstTimeLogin] = useLocalStorage('firstTimeLogin', true);
  const [isOpen, setIsOpen] = React.useState(isFirstTimeLogin);

  return (
    <section className="flex min-h-screen">
      <div className="flex max-w-full flex-1 flex-col justify-between py-6 pl-8 pr-[150px] sm:max-w-xs">
        <div>
          <img src={logo} alt="Enjicalc Logo" className="w-[104px]" />

          <div className="mt-[70px] space-y-2">
            <a
              href="https://enjicalc.gitbook.io/documentation/"
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full"
            >
              <SidebarButton>Documentation</SidebarButton>
            </a>
            <a
              href="https://enjicalc.canny.io/feedback"
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full"
            >
              <SidebarButton>Feeback Board</SidebarButton>
            </a>
            {!loading && <SidebarButton onClick={handleStart}>Quick Start</SidebarButton>}
          </div>

          {!loading && (
            <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Would like to do a Quick Start?</DialogTitle>
                  <DialogClose
                    onClick={() => {
                      setIsOpen(false);
                      setIsFirstTimeLogin(false);
                    }}
                  />
                </DialogHeader>
                <DialogFooter>
                  <Button
                    className={`text-[15px] ${QUICK_START_TOUR_STEP_NAMES.CreateNewWorkspace}`}
                    onClick={() => {
                      setIsOpen(false);
                      setIsFirstTimeLogin(false);
                      handleStart();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="text-[15px]"
                    variant="outline"
                    onClick={() => {
                      setIsFirstTimeLogin(false);
                      setIsOpen(false);
                    }}
                  >
                    No
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </section>
  );
};
