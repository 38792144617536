import React, { ReactElement } from 'react';
import { EnjicalcSymbol, EnjicalcWorkspace } from '../../../libenjc/enjc-workspace';
import { SymbolEditor } from '../symbol-editor';
import { CloseSymbolEditorDialog } from '../symbol-editor/CloseSymbolEditorDialog';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';
import { Dialog, DialogContent } from 'src/shadcn';

interface IProps {
  readonly symbol: EnjicalcSymbol | undefined;
  readonly onClose: () => void;
}

const SymbolModalViewF = ({ symbol, onClose }: IProps): ReactElement => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = React.useState(false);

  const handleDialogClose = (isSaved: boolean) => {
    if (isSaved) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  };

  const handleConfirmClose = () => {
    setDialogOpen(false);
    onClose();
  };

  const handleCancelClose = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    if (isSaveButtonEnabled) {
      setDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleSaveButtonState = React.useCallback((isEnabled: boolean) => {
    setSaveButtonEnabled(isEnabled);
  }, []);

  return (
    <>
      {symbol && (
        <Dialog open={!!symbol}>
          <DialogContent
            className={`h-[600px] max-w-[1000px] ${QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolGamma}`}
            withCloseButton={false}
          >
            {symbol && (
              <SymbolEditor
                symbol={symbol}
                isOpen={isDialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleConfirmClose}
                onCloseModal={onClose}
                onSaveButtonStateChange={handleSaveButtonState}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      {isSaveButtonEnabled && (
        <CloseSymbolEditorDialog isOpen={isDialogOpen} onClose={handleCancelClose} onConfirm={handleConfirmClose} />
      )}
    </>
  );
};

export const SymbolModalView = React.memo(SymbolModalViewF);
