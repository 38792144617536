import React from 'react';
import { getWorkspaceItemTitle, TSectionId } from '../../libenjc/enjc-workspace';
import {
  editSetSectionTitle,
  EnjicalcWorkspaceUpdateMessage,
  WorkspaceEditHistoryEntry,
} from '../../libenjc/enjc-workspace-editing';
import { useCtxEnjicalcWorkspace } from '../../libenjc/enjc-react/enjc-react-context';
import { EnjcWorkspaceSectionFragment } from '../../libenjc/enjicalc-graphql';
import { SearchInput } from '../../components/misc';
import { SheetMenu } from './SheetMenu';
import { QUICK_START_TOUR_STEP_NAMES, QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/shadcn';

// TODO: replace with interface with typename, id and title
type TSheet = EnjcWorkspaceSectionFragment;

interface IProps {
  readonly sheets: ReadonlyArray<TSheet>;
  readonly openSheets: ReadonlyArray<TSheet>;
  readonly activeSheet?: TSheet;
  readonly onSheetOpen: (sheetSectionId: TSectionId) => void;
  readonly onSheetCreate: () => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => void;

  readonly onSearchTermChange?: (text: string) => void;
}

const WorkspaceSheetsSidebarPrimaryF = ({
  sheets,
  openSheets,
  activeSheet,
  onSheetOpen,
  onSheetCreate,
  performWorkspaceEdit,
  onSearchTermChange,
}: IProps): React.ReactElement => {
  const { setCurrentStep, setIsOpen, isOpen } = useQuickStartTour();

  const { workspace } = useCtxEnjicalcWorkspace();

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [editingSheetId, setEditingSheetId] = React.useState<string | null>(null);
  const [titleValue, setTitleValue] = React.useState<string>('');

  const filteredSheets = React.useMemo(() => {
    return sheets.filter(
      (sheet) => !searchTerm || getWorkspaceItemTitle(sheet).toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, sheets]);

  const handleSearchTermChange = React.useCallback(
    (nextSearchTerm: string) => {
      setSearchTerm(nextSearchTerm);
      onSearchTermChange && onSearchTermChange(nextSearchTerm);
    },
    [onSearchTermChange],
  );

  const handleTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTitleValue(e.target.value),
    [],
  );

  const setEnjcWorkspaceTitle = React.useCallback(
    (nextTitle: string) => {
      if (editingSheetId) {
        const hEntry = editSetSectionTitle(workspace, editingSheetId, nextTitle);
        performWorkspaceEdit(hEntry);
        setEditingSheetId(null);
      }
    },
    [performWorkspaceEdit, workspace, editingSheetId],
  );

  const handleTitleKeyDown = React.useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setEnjcWorkspaceTitle(titleValue);
      }
    },
    [titleValue, setEnjcWorkspaceTitle],
  );

  const handleInputClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const handleRenameClick = React.useCallback((sheetId: string, currentTitle: string) => {
    setEditingSheetId(sheetId);
    setTitleValue(currentTitle);
  }, []);

  const handleInputBlur = React.useCallback(() => {
    setEnjcWorkspaceTitle(titleValue);
  }, [titleValue, setEnjcWorkspaceTitle]);

  React.useEffect(() => {
    if (isOpen) {
      setIsOpen(true);
      setCurrentStep(QUICK_START_TOUR_STEP_COUNT.CreateSheet);
    }
  }, [setCurrentStep, setIsOpen, isOpen]);

  const renderTitle = React.useCallback(
    (sheet: TSheet) => {
      if (editingSheetId === sheet.id) {
        return (
          <input
            className="size-full border-b-0 bg-gray-100 pl-4 placeholder:text-gray-400 focus:border-b-2 focus:border-primary-green focus:outline-none"
            placeholder="Empty"
            value={titleValue}
            onKeyDown={handleTitleKeyDown}
            onChange={handleTitleChange}
            onBlur={handleInputBlur}
            autoFocus
            onClick={handleInputClick}
          />
        );
      }

      return <p className="ml-4 truncate text-left text-black">{getWorkspaceItemTitle(sheet) || 'Untitled'}</p>;
    },
    [editingSheetId, titleValue, handleTitleKeyDown, handleTitleChange, handleInputClick, handleInputBlur],
  );

  const navigate = useNavigate();

  return (
    <div className="flex size-full flex-col p-0 pb-2">
      <div className="flex w-full items-start justify-between border-b border-b-gray-300 px-4 py-[10px]">
        <p className="truncate text-xs text-gray-500">{'WORKSPACE SHEETS'}</p>
      </div>
      <div className="flex flex-col items-start gap-3">
        <div
          onClick={() => navigate(`/workspace/${workspace.id}/sheets`)}
          className={`flex w-full cursor-pointer items-center justify-between py-2 pl-4 hover:bg-gray-100`}
        >
          {'Starting Page'}
        </div>

        <div className="mx-4 max-w-[210px]">
          <SearchInput searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} />
        </div>

        <Button
          className={`mx-[16px] my-0 w-[210px] pl-[20px] text-[15px] ${QUICK_START_TOUR_STEP_NAMES.CreateSheet}`}
          onClick={onSheetCreate}
        >
          {'Create Sheet'}
        </Button>
      </div>

      <div className="flex grow flex-col space-y-0 overflow-y-auto p-0 pt-3">
        {filteredSheets.map((sheet) => (
          <div
            key={sheet.id}
            className={`flex cursor-pointer items-center justify-between pr-[3px] ${
              sheet.id === activeSheet?.id ? 'bg-gray-50' : openSheets.find((s) => sheet.id === s?.id) && 'bg-white'
            } hover:bg-gray-100 ${QUICK_START_TOUR_STEP_NAMES.OpenSheet}`}
            onClick={() => {
              onSheetOpen(sheet.id);
              setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbol);
            }}
          >
            {renderTitle(sheet)}

            <SheetMenu
              workspace={workspace}
              sheet={sheet}
              onSheetOpen={onSheetOpen}
              performWorkspaceEdit={performWorkspaceEdit}
              onRenameTitle={() => handleRenameClick(sheet.id, getWorkspaceItemTitle(sheet))}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export const WorkspaceSheetsSidebarPrimary = React.memo(WorkspaceSheetsSidebarPrimaryF);
