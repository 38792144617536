import { Button } from 'src/shadcn';
import React, { ReactElement } from 'react';

const AppWelcomeF = (): ReactElement => {
  return (
    <div className="flex h-[calc(100vh-35px)] w-full flex-col justify-between space-y-0 pl-44 pr-12">
      <div className="hidden md:block">
        <p className="pt-24 text-lg text-black">Dear user,</p>
        <p className="w-[600px] pt-8 text-lg text-black">
          Welcome to our closed beta tests of Enjicalc. We would appreciate your support and feedback on our product.
        </p>
        <p className="w-[600px] pt-8 text-lg text-black">
          By visiting our Canny Feedback platform and actively participating in discussions, voting on feature
          suggestions, or sharing your thoughts, you are playing a crucial role in shaping the future of our product.
          Your input is not just valuable, it's essential.
        </p>
        <p className="w-[600px] pt-8 text-lg text-black">Let's make Enjicalc better together.</p>
        <p className="w-[600px] pt-8 text-lg text-black">Best regards,</p>
        <p className="w-[600px] text-lg text-black">Team Enjicalc</p>
        <div className="flex gap-8 py-12">
          <a
            href="https://enjicalc.canny.io/feedback"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            <Button className="h-[50px] w-[300px] text-[15px]" variant="outline">
              Submit Feedback
            </Button>
          </a>
          <a
            href="https://docs.enji.io/documentation"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            <Button className="h-[50px] w-[300px] text-[15px]" variant="outline">
              Documentation
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export const AppWelcome = React.memo(AppWelcomeF);
