import { StepType, useTour } from '@reactour/tour';
import React from 'react';

export const ALPHA = 'alpha';
export const BETA = 'beta';
export const GAMMA = 'gamma';

export const QUICK_START_TOUR_STYLES = {
  popover: (base: any) => ({
    ...base,
    borderRadius: 10,
  }),
  maskArea: (base: any) => ({ ...base, rx: 10 }),
  maskWrapper: (base: any) => ({ ...base, color: '#939393' }),
  close: (base: any) => ({ ...base, left: 'auto', right: 15, top: 15 }),
};

export const PADDING = {
  mask: 5,
};

export enum QUICK_START_TOUR_STEP_NAMES {
  CreateNewWorkspace = 'createNewWorkspace',
  OpenWorkspace = 'openWorkspace',
  CreateSheet = 'createSheet',
  OpenSheet = 'openSheet',

  AddSymbol = 'addSymbol',
  OpenSymbolMenu = 'openSymbolMenu',
  ModifySymbol = 'modifySymbol',
  WriteSymbolName = 'writeSymbolName',
  AddValueForSymbol = 'addValueForSymbol',
  SaveSymbol = 'saveSymbol',

  AddSymbolBetta = 'addSymbolBetta',
  OpenSymbolMenuBetta = 'openSymbolMenuBetta',
  ModifySymbolBetta = 'modifySymbolBetta',
  WriteSymbolNameBetta = 'writeSymbolNameBetta',
  AddValueForSymbolBetta = 'addValueForSymbolBetta',
  SaveSymbolBetta = 'saveSymbolBetta',

  AddSymbolGamma = 'addSymbolGamma',
  OpenSymbolMenuGamma = 'openSymbolMenuGamma',
  ModifySymbolGamma = 'modifySymbolGamma',
  WriteSymbolNameGamma = 'writeSymbolNameGamma',
  AddValueForSymbolGamma = 'addValueForSymbolGamma',
  SaveSymbolGamma = 'saveSymbolGamma',

  Print = 'print',
}

export enum QUICK_START_TOUR_STEP_COUNT {
  CreateNewWorkspace,
  OpenWorkspace,
  CreateSheet,
  OpenSheet,
  AddSymbol,
  OpenSymbolMenu,
  ModifySymbol,
  WriteSymbolName,
  AddValueForSymbol,
  SaveSymbol,
  AddSymbolBetta,
  OpenSymbolMenuBetta,
  ModifySymbolBetta,
  WriteSymbolNameBetta,
  AddValueForSymbolBetta,
  SaveSymbolBetta,
  AddSymbolGamma,
  OpenSymbolMenuGamma,
  ModifySymbolGamma,
  WriteSymbolNameGamma,
  AddValueForSymbolGamma,
  SaveSymbolGamma,
  Print,
}

export const CONFETTI_DURATION = 5000;

const makeSelector = (step: QUICK_START_TOUR_STEP_NAMES) => `.${step}`;

export const useQuickStartTour = () => {
  const { setCurrentStep, currentStep, setIsOpen, isOpen } = useTour();

  const [isFinished, setIsFinished] = React.useState<boolean>(false);

  const isAlpha = currentStep <= QUICK_START_TOUR_STEP_COUNT.SaveSymbol;
  const isBeta =
    currentStep >= QUICK_START_TOUR_STEP_COUNT.SaveSymbol && currentStep < QUICK_START_TOUR_STEP_COUNT.AddSymbolGamma;

  const handleStart = React.useCallback(() => {
    setIsOpen(true);
    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.CreateNewWorkspace);
  }, [setIsOpen, setCurrentStep]);

  const handleFinish = React.useCallback(() => {
    setIsFinished(true);
    setTimeout(() => {
      setIsFinished(false);
    }, CONFETTI_DURATION);
  }, []);

  const QUICK_START_TOUR_STEPS: StepType[] = [
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.CreateNewWorkspace),
      content:
        'Start by creating your first workspace to begin organising your projects. Press this button to get started!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenWorkspace),
      content:
        'Click on the newly created workspace to open and start managing your projects. Select this card to proceed!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.CreateSheet),
      content: "Click on 'Create Sheet' to makeSelector a new sheet to your workspace.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSheet),
      content: "Click on the 'Untitled' sheet to open it and start editing your data.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddSymbol),
      content:
        "Click on 'Add Symbol' to insert a new symbol into your calculations. This will allow you to define and use variables within your sheet!",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenu),
      content:
        'Click on the three-dot menu to open additional options. This will allow you to further customise and manage your symbol settings!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.ModifySymbol),
      // padding: {
      //   mask: [15, 5, 55, 55],
      // },
      content: "Select 'Modify Symbol' from the menu to edit the details of your symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.WriteSymbolName),
      content: "Create a variable by entering 'alpha'",
      padding: {
        mask: [20, -10, -10, -10],
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddValueForSymbol),
      content: "Delete 0 and enter a value of 1 for the variable 'alpha'",
      padding: {
        mask: 5,
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.SaveSymbol),
      content: "Click 'Accept' to confirm the creation of the variable 'alpha' with the assigned value.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddSymbolBetta),
      content:
        "Click on 'Add Symbol' to insert a new symbol into your calculation. This will allow you to define and use variables within your sheet!",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuBetta),
      content:
        'Click on the three-dot menu to open additional options. This will allow you to further customise and manage your symbol settings!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.ModifySymbolBetta),
      // padding: {
      //   mask: [15, 5, 55, 55],
      // },
      content: "Select 'Modify Symbol' from the menu to edit the details of your symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameBetta),
      content: "Create a variable by entering 'beta'",
      padding: {
        mask: [20, -10, -10, -10],
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolBetta),
      content: "Delete 0 and enter a value of 2 for the variable 'beta'",
      padding: {
        mask: 5,
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.SaveSymbolBetta),
      content: "Click 'Accept' to confirm the creation of the variable 'beta' with the assigned value.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddSymbolGamma),
      content:
        "Click on 'Add Symbol' to insert a new symbol into your calculation. This will allow you to define and use variables within your sheet!",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuGamma),
      content:
        'Click on the three-dot menu to open additional options. This will allow you to further customise and manage your symbol settings!',
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.ModifySymbolGamma),
      content: "Select 'Modify Symbol' from the menu to edit the details of your symbol.",
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameGamma),
      content: "Create a variable by entering 'gamma'",
      padding: {
        mask: [20, -10, -10, -10],
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolGamma),
      content: "Delete 0 and enter 𝛼 + 𝛽 a value of 2 for the variable 'gamma' and press 'Accept' button",
      actionAfter: () => {
        handleFinish();
      },
    },
    {
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.SaveSymbolGamma),
      content: "Click 'Accept' to confirm the creation of the variable 'gamma' with the assigned value.",
    },
    {
      content: "If you want to print, click 'Print to PDF' or press ESC to finish.",
      selector: makeSelector(QUICK_START_TOUR_STEP_NAMES.Print),
      styles: {
        popover: (base: any) => ({
          ...base,
          borderRadius: 10,
          top: 50,
          left: 50,
        }),
      },
    },
  ];

  return {
    setCurrentStep,
    setIsOpen,
    isOpen,
    isAlpha,
    isBeta,
    currentStep,
    isFinished,
    handleStart,
    handleFinish,
    QUICK_START_TOUR_STEPS,
  };
};
